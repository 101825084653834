
function INSIDER_ICON({height, width, ...rest }) {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="161"
    //   height="26"
    //   viewBox="0 0 161 26"
    //   fill="none"
    //   {...rest}
    // >
    //   <g clipPath="url(#clip0_59_1204)">
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M0.644775 0.964469H4.61257V24.3113H0.644775V0.964469ZM32.7918 15.8957L16.6728 0.604574V24.3108H20.4879V9.12228L36.6069 24.6718V0.964469H32.7918V15.8957ZM57.3139 11.3067L53.5378 9.93739C51.5918 9.25405 50.4848 8.56864 50.4848 6.85822C50.4848 4.95718 52.2406 4.04416 54.6053 4.04416C57.3518 4.04416 59.4889 5.07072 61.4339 6.55405V2.48478C59.5653 1.26864 57.1617 0.58374 54.5289 0.58374C50.2178 0.58374 46.6318 2.82697 46.6318 6.93374C46.6318 10.7738 48.997 12.4473 52.1253 13.5879L55.5206 14.8051C57.6188 15.6035 58.8017 16.3264 58.8017 18.1509C58.8017 20.2421 56.932 21.1931 54.2614 21.1931C51.5154 21.1931 48.9585 20.2051 46.8219 18.4931V22.6379C48.463 23.7791 51.1331 24.6916 54.2614 24.6916C59.0313 24.6916 62.6546 22.3332 62.6546 18.0749C62.6546 14.691 60.9767 12.6374 57.3139 11.3067ZM71.5934 0.964469H75.5606V24.3113H71.5934V0.964469ZM96.8032 0.96499H87.8183V24.3113H96.8032C103.709 24.3113 108.173 19.7483 108.173 12.6374C108.173 5.52749 103.708 0.96499 96.8032 0.96499ZM96.6505 20.7759H91.7866V4.46291H96.6505C101.57 4.46291 104.204 7.61864 104.204 12.6374C104.204 17.6181 101.571 20.7759 96.6505 20.7759ZM117.177 24.3113H131.387V20.7759H121.068V13.5405H130.319V10.0801H121.068V4.46239H131.387V0.964469H117.177V24.3113ZM151.979 14.691C155.68 14.1207 157.425 11.877 157.425 7.9603C157.425 3.3978 154.297 0.964469 149.451 0.964469H140.953V24.3113H144.921V14.8431H147.744L155.899 24.3113H160.401L151.979 14.691ZM144.921 11.5348V4.38634H149.375C152.007 4.38634 153.495 5.79312 153.495 7.9603C153.495 10.128 152.007 11.5348 149.375 11.5348H144.921Z"
    //       fill="#004152"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_59_1204">
    //       <rect
    //         width="160"
    //         height="25"
    //         fill="white"
    //         transform="translate(0.644775 0.134521)"
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>
    <img src="/media/as-seen-in/cbs-text.png" style={{ height, width }}
      {...rest}></img>
  );
}

export default INSIDER_ICON;
